import React, {FC, useEffect} from 'react';
import moment from 'moment';
import cn from 'classnames'
import css from './TelegramWidget.module.scss'
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getTelegram} from "../../store/reducers/TelegramSlice";
import {DATE_FORMAT, TELEGRAM} from "../../constants";
import Icon from "../Icon";
import {ICON_VARIANT} from "../../assets/svg";
import ArticleMedia from "../Article/ArticleMedia";
import Spinner from "../Spinner";
import {useTranslate} from "../../hooks/useTranslate";
import {declOfNum} from "../../utils/utils";

const TelegramWidget:FC = () => {
    const { lang } = useAppSelector(state => state.auth);
    const { data, chanel, membersCount, isLoading } = useAppSelector(state => state.telegram);
    const dispatch = useAppDispatch();
    const channel = TELEGRAM[lang].channel
    const link = TELEGRAM[lang].link
    const t = useTranslate()

    useEffect(() => {
        dispatch(getTelegram({url: channel}))
    }, [dispatch, channel]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(getTelegram({url: channel}))
        }, 1000 * 60)

        return () => {
            clearInterval(intervalId)
        }
    }, [dispatch, channel])

    return (
        <div className={css.telegram}>
            <a href={link} target={"_blank"} className={css.header}>
                <span className={css.icon}>
                    <Icon name={ICON_VARIANT.LOGOMARK}/>
                </span>
                <span className={css.info}>
                    <span className={css.title}>{t.telegramWidget.title}</span>
                    <span className={css.counter}>{t.telegramWidget.chanel} {membersCount} {declOfNum(membersCount, [t.telegramWidget.count.nominative, t.telegramWidget.count.singular, t.telegramWidget.count.plural])}</span>
                </span>
            </a>
            <div className={css.list}>
                {isLoading && !data.length
                    ? <Spinner/>
                    : data.map(article =>
                        <div className={css.post} key={article.article_id}>
                            <a href={article.article_lead} target={"_blank"} className={css.postHeader}>
                                <div className={css.postIcon}><Icon name={ICON_VARIANT.TELEGRAM}/></div>
                                <div className={css.postInfo}>
                                    <span className={css.postTitle}>{t.telegramWidget.title}</span>
                                    <span
                                        className={css.postDate}>{moment(article.article_date).format(DATE_FORMAT[lang].FORMAT_TIME_AND_DATE)}</span>
                                </div>
                            </a>
                            {article.medias.length
                                ? <div className={cn(css.postMedias, {
                                    [css.postMediasGrid]: article.medias.length > 1,
                                    [css.postMediasLarge]: article.medias.length > 1 && article.medias.length % 2 === 1
                                })}>
                                    {article.medias.map(media =>
                                        <ArticleMedia media={[media]} key={media.media_id}/>
                                    )}
                                </div>
                                : null
                            }
                            <div className={css.postBody} dangerouslySetInnerHTML={{__html: article.article_body}}/>
                            <a href={article.article_lead} target={"_blank"} className={css.postLink}>{article.article_lead}</a>
                        </div>
                    )
                }
            </div>
        </div>
)
}

export default TelegramWidget