import React, {FC} from 'react';
import List from "../../List";
import {initialStateList, LIST_NAME} from "../../../store/reducers/LSlice";
import {LIST_VARIANT} from "../../List/ListItem";
import {SIDS} from "../../../constants";
import {useAppSelector} from "../../../hooks/redux";
import css from './LottieList.module.scss'
import Icon from "../../Icon";
import {ICON_VARIANT} from "../../../assets/svg";
import {useTranslate} from "../../../hooks/useTranslate";

const LottieList:FC = () =>{
    const { lang } = useAppSelector(state => state.auth);
    const t = useTranslate();

    return (
        <div className={css.lenta}>
            <div className={css.header}>
                <div className={css.headerWrapper}>
                    <span className={css.headerIcon}><Icon name={ICON_VARIANT.LOGOMARK}/></span>
                    <span className={css.headerTitle}>{t.gs1.feedTitle}</span>
                </div>
            </div>
            <div className={css.list}>
                <List
                    name={LIST_NAME.INDEX}
                    variant={LIST_VARIANT.WIDGET}
                    onArticle={() => {
                    }}
                    datepicker={false}
                    withAuth={false}
                    isMore={false}
                    filterParams={{
                        ...initialStateList.filter,
                        ...{sid_list: [SIDS[lang].INDEX]}
                    }}
                />
            </div>
            <div className={css.footer}>
                <a href={"/"} className={css.footerLink}>{t.gs1.buySubscribe}</a>
            </div>
        </div>
    )
}

export default LottieList