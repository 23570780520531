import { ReactComponent as arrowLeft} from './arrowLeft.svg';
import { ReactComponent as arrowRight} from './arrowRight.svg';
import { ReactComponent as arrowUp} from './arrowUp.svg';
import { ReactComponent as arrowDown} from './arrowDown.svg';
import { ReactComponent as arrowDoubleLeft} from './arrowDoubleLeft.svg';
import { ReactComponent as arrowDoubleRight} from './arrowDoubleRight.svg';
import { ReactComponent as arrowBack} from './arrowBack.svg';
import { ReactComponent as logo} from './logo.svg';
import { ReactComponent as logomark} from './logomark.svg';
import { ReactComponent as logo_inverse} from './logo_inverse.svg';
import { ReactComponent as logoSputnik} from './logoSputnik.svg';
import { ReactComponent as logoSputnik_inverse} from './logoSputnik_inverse.svg';
import { ReactComponent as user} from './user.svg';
import { ReactComponent as telegram} from './telegram.svg';
import { ReactComponent as telegram2} from './telegram2.svg';
import { ReactComponent as vk} from './vk.svg';
import { ReactComponent as facebook} from './facebook.svg';
import { ReactComponent as google} from './google.svg';
import { ReactComponent as ok} from './ok.svg';
import { ReactComponent as apple} from './apple.svg';
import { ReactComponent as close} from './close.svg';
import { ReactComponent as close2} from './close2.svg';
import { ReactComponent as check} from './check.svg';
import { ReactComponent as line} from './line.svg';
import { ReactComponent as star} from './star.svg';
import { ReactComponent as starFill} from './starFill.svg';
import { ReactComponent as star_big} from './star_big.svg';
import { ReactComponent as comments} from './comments.svg';
import { ReactComponent as more} from './more.svg';
import { ReactComponent as share} from './share.svg';
import { ReactComponent as sorting} from './sorting.svg';
import { ReactComponent as spinner} from './spinner.svg';
import { ReactComponent as search} from './search.svg';
import { ReactComponent as download} from './download.svg';
import { ReactComponent as print} from './print.svg';
import { ReactComponent as mail} from './mail.svg';
import { ReactComponent as copy} from './copy.svg';
import { ReactComponent as analytics} from './analytics.svg';
import { ReactComponent as letter} from './letter.svg';
import { ReactComponent as filter} from './filter.svg';
import { ReactComponent as profile} from './profile.svg';
import { ReactComponent as pin} from './pin.svg';
import { ReactComponent as pinFill} from './pinFill.svg';
import { ReactComponent as settings} from './settings.svg';
import { ReactComponent as tab} from './tab.svg';
import { ReactComponent as read} from './read.svg';
import { ReactComponent as drag} from './drag.svg';
import { ReactComponent as range} from './range.svg';
import { ReactComponent as minus} from './minus.svg';
import { ReactComponent as plus} from './plus.svg';
import { ReactComponent as picker} from './picker.svg';
import { ReactComponent as time} from './time.svg';
import { ReactComponent as calendar} from './calendar.svg';
import { ReactComponent as pause} from './pause.svg';
import { ReactComponent as play} from './play.svg';
import { ReactComponent as fullscreen} from './fullscreen.svg';
import { ReactComponent as fullscreenOff} from './fullscreenOff.svg';
import { ReactComponent as sound} from './sound.svg';
import { ReactComponent as mute} from './mute.svg';
import { ReactComponent as text} from './text.svg';
import { ReactComponent as logout} from './logout.svg';
import { ReactComponent as edit} from './edit.svg';
import { ReactComponent as delete_icon} from './delete.svg';
import { ReactComponent as listMenu} from './listMenu.svg';
import { ReactComponent as listCompact} from './listCompact.svg';
import { ReactComponent as listLead} from './listLead.svg';
import { ReactComponent as listStandart} from './listStandart.svg';
import { ReactComponent as listTitle} from './listTitle.svg';
import { ReactComponent as sort} from './sort.svg';
import { ReactComponent as subscribe} from './subscribe.svg';
import { ReactComponent as subscribeBlack} from './subscribeBlack.svg';
import { ReactComponent as subscribeSputnik} from './subscribeSputnik.svg';
import { ReactComponent as subscribeBlue} from './subscribeBlue.svg';
import { ReactComponent as subscribeSputnikBlack} from './subscribeSputnikBlack.svg';
import { ReactComponent as flash} from './flash.svg';
import { ReactComponent as notFound} from './notFound.svg';
import { ReactComponent as question} from './question.svg';
import { ReactComponent as importTelegram} from './import.svg';
import { ReactComponent as action} from './action.svg';
import { ReactComponent as external} from './external.svg';
import { ReactComponent as warning} from './warning.svg';
import { ReactComponent as mobile} from './mobile.svg';
import { ReactComponent as message} from './message.svg';
import { ReactComponent as manual} from './manual.svg';

export enum ICON_VARIANT {
    ARROW_LEFT = 'arrowLeft',
    ARROW_RIGHT = 'arrowRight',
    ARROW_UP = 'arrowUp',
    ARROW_DOWN = 'arrowDown',
    ARROW_BACK = 'arrowBack',
    ARROW_DOUBLE_LEFT = 'arrowDoubleLeft',
    ARROW_DOUBLE_RIGHT = 'arrowDoubleRight',
    LOGO = 'logo',
    LOGOMARK = 'logomark',
    LOGO_INVERSE = 'logo_inverse',
    LOGO_SPUTNIK = 'logoSputnik',
    LOGO_SPUTNIK_INVERSE = 'logoSputnik_inverse',
    USER = 'user',
    PROFILE = 'profile',
    TELEGRAM = 'telegram',
    TELEGRAM_2 = 'telegram2',
    FACEBOOK = 'facebook',
    VK = 'vk',
    OK = 'ok',
    APPLE = 'apple',
    GOOGLE = 'google',
    CLOSE = 'close',
    CLOSE_2 = 'close2',
    CHECK = 'check',
    LINE = 'line',
    STAR = 'star',
    STAR_FILL = 'starFill',
    STAR_BIG = 'star_big',
    COMMENTS = 'comments',
    SHARE = 'share',
    MORE = 'more',
    SORTING = 'sorting',
    SPINNER = 'spinner',
    SEARCH = 'search',
    DOWNLOAD = 'download',
    PRINT = 'print',
    MAIL = 'mail',
    COPY = 'copy',
    ANALYTICS = 'analytics',
    LETTER = 'letter',
    FILTER = 'filter',
    PIN = 'pin',
    PIN_FILL = 'pinFill',
    SETTINGS = 'settings',
    TAB = 'tab',
    READ = 'read',
    DRAG = 'drag',
    RANGE = 'range',
    MINUS = 'minus',
    PLUS = 'plus',
    PICKER = 'picker',
    TIME = 'time',
    CALENDAR = 'calendar',
    PLAY = 'play',
    PAUSE = 'pause',
    FULLSCREEN = 'fullscreen',
    FULLSCREEN_OFF = 'fullscreenOff',
    SOUND = 'sound',
    MUTE = 'mute',
    TEXT = 'text',
    LOGOUT = 'logout',
    EDIT = 'edit',
    DELETE = 'delete_icon',
    LIST_MENU = 'listMenu',
    LIST_COMPACT = 'listCompact',
    LIST_LEAD = 'listLead',
    LIST_STANDART = 'listStandart',
    LIST_TITLE = 'listTitle',
    SORT = 'sort',
    SUBSCRIBE = 'subscribe',
    SUBSCRIBE_SPUTNIK = 'subscribeSputnik',
    SUBSCRIBE_BLACK = 'subscribeBlack',
    SUBSCRIBE_BLUE = 'subscribeBlue',
    SUBSCRIBE_SPUTNIK_BLACK = 'subscribeSputnikBlack',
    FLASH = 'flash',
    NOT_FOUND = 'notFound',
    QUESTION = 'question',
    IMPORT = 'importTelegram',
    ACTION = 'action',
    EXTERNAL = 'external',
    WARNING = 'warning',
    MOBILE = 'mobile',
    MESSAGE = 'message',
    MANUAL = 'manual'
}

export const Icons = {
    arrowLeft,
    arrowRight,
    arrowUp,
    arrowDown,
    arrowBack,
    arrowDoubleLeft,
    arrowDoubleRight,
    logo,
    logomark,
    logo_inverse,
    logoSputnik,
    logoSputnik_inverse,
    user,
    profile,
    telegram,
    telegram2,
    facebook,
    vk,
    ok,
    apple,
    google,
    close,
    close2,
    check,
    line,
    star,
    starFill,
    star_big,
    comments,
    share,
    more,
    sorting,
    spinner,
    search,
    download,
    print,
    mail,
    copy,
    analytics,
    letter,
    filter,
    pin,
    pinFill,
    settings,
    tab,
    read,
    drag,
    range,
    minus,
    plus,
    picker,
    time,
    calendar,
    play,
    pause,
    fullscreen,
    fullscreenOff,
    sound,
    mute,
    text,
    logout,
    edit,
    delete_icon,
    listMenu,
    listCompact,
    listLead,
    listStandart,
    listTitle,
    sort,
    subscribe,
    subscribeBlack,
    subscribeSputnik,
    subscribeBlue,
    subscribeSputnikBlack,
    flash,
    notFound,
    question,
    importTelegram,
    action,
    external,
    warning,
    mobile,
    message,
    manual
};