import React, {FC} from 'react';
import cn from 'classnames'
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css/effect-fade';
import SwiperCore, {A11y, Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import widget1 from "../../lottie/images/widget1.webm";
import widget2 from "../../lottie/images/widget2.webm";
import widget3 from "../../lottie/images/widget3.webm";
import widget4 from "../../lottie/images/widget4.webm";
import widget5 from "../../lottie/images/widget5.webm";
import widget6 from "../../lottie/images/widget6.webm";
import widget7 from "../../lottie/images/widget7.webm";
import widget8 from "../../lottie/images/widget8.webm";
import widgetBG1 from "../../lottie/images/bg/widget1.mp4";
import widgetBG2 from "../../lottie/images/bg/widget2.mp4";
import widgetBG3 from "../../lottie/images/bg/widget3.mp4";
import widgetBG4 from "../../lottie/images/bg/widget4.mp4";
import widgetBG5 from "../../lottie/images/bg/widget5.mp4";
import widgetBG6 from "../../lottie/images/bg/widget6.mp4";
import css from './Lottie.module.scss';
import Icon from "../Icon";
import {ICON_VARIANT} from "../../assets/svg";
import {useAppSelector} from "../../hooks/redux";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {THEME} from "../../types/IUser";
import {SIDS, SUBSCRIBE, TELEGRAM} from "../../constants";
import LottieNews from "./LottieNews";
import {useTranslate} from "../../hooks/useTranslate";
import {useBodyClass} from "../../hooks/useBodyClass";
import LottieList from "./LottieList/LottieList";
import LottieItem from "./item";
import {LOTTIE_PHOTOLENTA} from "../../lottie/slider";
import TelegramWidget from "../TelegramWidget";

SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination, A11y]);

const LottiePage:FC = () => {
    const { lang } = useAppSelector(state => state.auth);
    const windowScreen = useAppSelector(state => state.screen.screen);
    const {theme} = useAppSelector(state => state.auth);
    const photolenta = LOTTIE_PHOTOLENTA[lang][windowScreen]
    const t = useTranslate();

    const isSafariBrowser = () => {
        const ua = navigator.userAgent.toLowerCase();

        return ua.includes('safari') && !ua.includes('chrome') && !ua.includes('edge');
    };

    const isSafari = isSafariBrowser()

    useBodyClass(isSafari ? 'lottie' : 'lottie-transparent');

    return <>
        <div className={css.relative}>
            <div className={cn(css.section, css.main)}>
                <div className={css.info}>
                    <div className={css.title}>
                        {t.gs1.title}
                    </div>
                    <div className={css.description}>
                        {t.gs1.description}
                    </div>
                    <div className={css.buttons}>
                        <a href={SUBSCRIBE[lang].link} target={"_blank"} className={css.btn}>{t.gs1.subscribe}</a>
                        <a href={TELEGRAM[lang].link} target={"_blank"} className={cn(css.btn, css.outline)}><span
                            className={css.btnIcon}><Icon
                            name={ICON_VARIANT.TELEGRAM}/></span>{t.gs1.readTelegram}</a>
                    </div>
                </div>
                <div className={css.media}>
                    <div className={css.macbook}>
                        <div className={css.lottieNews}>
                            <LottieNews/>
                        </div>
                        <div className={css.lottieTelegram}>
                            {TELEGRAM[lang].channel
                                ? <TelegramWidget/>
                                : <LottieList/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LottieItem
            classNames={[css.reverse, css.h401]}
            title={t.gs1.section1.title}
            description={t.gs1.section1.description}
            video={isSafari ? widgetBG1 : widget1}/>
        <LottieItem
            classNames={[css.h379]}
            title={t.gs1.section2.title}
            description={t.gs1.section2.description}
            video={isSafari ? widgetBG2 : widget2}/>
        <LottieItem
            classNames={[css.reverse, css.h426]}
            title={t.gs1.section3.title}
            description={t.gs1.section3.description}
            video={isSafari ? widgetBG3 : widget3}/>
        <LottieItem
            classNames={[css.h395]}
            title={t.gs1.section4.title}
            description={t.gs1.section4.description}
            video={isSafari ? widgetBG4 : widget4}/>
        <LottieItem
            classNames={[css.reverse, css.h336]}
            title={t.gs1.section5.title}
            description={t.gs1.section5.description}
            video={isSafari ? widgetBG5 : widget6}/>
        <LottieItem
            classNames={[css.h480]}
            title={t.gs1.section6.title}
            description={t.gs1.section6.description}
            video={isSafari ? widgetBG6 : widget5}/>

        <div className={cn(css.section, css.tag)}>
            <div className={css.title}>
                {t.gs1.slider.title}
            </div>
            <div className={css.slider}>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={SCREEN_VARIANT.DESKTOP === windowScreen ? 24 : 16}
                    speed={300}
                >
                    <SwiperSlide>
                        <div className={css.slide}>
                            <div className={css.slideIcon}><Icon name={ICON_VARIANT.MOBILE}/></div>
                            <div className={css.slideTitle}>{t.gs1.slider.slide1.title}</div>
                            <div className={css.slideDescription}>{t.gs1.slider.slide1.description}</div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={css.slide}>
                            <div className={css.slideIcon}><Icon name={ICON_VARIANT.MESSAGE}/></div>
                            <div className={css.slideTitle}>{t.gs1.slider.slide2.title}</div>
                            <div className={css.slideDescription}>{t.gs1.slider.slide2.description}</div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={css.sliderLast}>
                        <div className={css.slide}>
                            <div className={css.slideIcon}><Icon name={ICON_VARIANT.MANUAL}/></div>
                            <div className={css.slideTitle}>{t.gs1.slider.slide1.title}</div>
                            <div className={css.slideDescription}>{t.gs1.slider.slide1.description}</div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
        <div className={cn(css.section, css.news)}>
            <div className={css.title}>
                {t.gs1.place.title}
            </div>
            <div className={css.newsList}>
                <div className={css.newsItem}>
                    <div className={css.newsTitle}>
                        {t.gs1.place.item1.title}
                    </div>
                    <div className={css.newDescription}>
                        {t.gs1.place.item1.description}
                    </div>
                </div>
                <div className={css.newsItem}>
                    <div className={css.newsTitle}>
                        {t.gs1.place.item2.title}
                    </div>
                    <div className={css.newDescription}>
                        {t.gs1.place.item2.description}
                    </div>
                </div>
            </div>
            <div className={css.newsBtn}>
                <a href={SUBSCRIBE[lang].link} className={cn(css.btn, css.light)}>{t.gs1.place.more}</a>
            </div>
        </div>

        <div className={cn(css.section, css.newsMedia)}>
            <div className={css.newsMediaWrapper}>
                <div className={css.newsMediaImg}>
                    <Swiper
                        effect="fade"
                        autoplay={{
                            delay: 1000
                        }}
                        speed={300}
                        allowTouchMove={false} // Отключаем свайп
                    >
                        {photolenta?.map(item =>
                            <SwiperSlide>
                                <img src={item} alt=""/>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    {/*<LottieVideo video={windowScreen === SCREEN_VARIANT.DESKTOP ? widget8 : widget7}/>*/}
                    {/*<img src={widget8} alt=""/>*/}
                </div>
            </div>
        </div>

        <div className={css.footer}>
            <div className={css.footerWrapper}>
                <div className={css.footerLogo}>
                    {theme === THEME.RIA
                        ? <Icon name={ICON_VARIANT.LOGO}/>
                        : <Icon name={ICON_VARIANT.LOGO_SPUTNIK}/>
                    }
                </div>
                <div className={css.footerInfo}>
                    <div className={css.footerList}>
                        {t.footer.tags.map((item, i) =>
                            <a key={i} href={item.link} className={css.footerListItem}>{item.title}</a>
                        )}
                    </div>
                    <div className={css.copyright}>{t.gs1.copyright}</div>
                </div>
            </div>
        </div>
    </>
}

export default LottiePage