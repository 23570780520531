import { useEffect } from "react";

export const useBodyClass = (className: string) => {
    useEffect(() => {
        // Разделяем строку className на массив классов
        const classList = className.split(" ").filter(Boolean);

        // Добавляем каждый класс из массива к body
        classList.forEach((cls: string) => document.body.classList.add(cls));

        return () => {
            // Удаляем каждый класс из массива при размонтировании
            classList.forEach((cls: string) => document.body.classList.remove(cls));
        };
    }, [className]); // Следим за изменением className
};

