import React, { FC, useState, useRef, useEffect } from 'react';
import cn from 'classnames'
import LottieVideo from "../LottieVideo";
import css from '../Lottie.module.scss';

interface PropsLottieItem {
    title?: string
    classNames: any
    description?: string
    video: any
}

const LottieItem:FC<PropsLottieItem> = ({title, classNames, description, video}) => {
    return (
        <div className={cn(css.section, classNames)}>
            <div className={css.info}>
                <div className={css.title} dangerouslySetInnerHTML={{__html: title}}/>
                <div className={css.description} dangerouslySetInnerHTML={{__html: description}}/>
            </div>
            <div className={css.media}>
                <LottieVideo video={video}/>
            </div>
        </div>
    );
}

export default LottieItem