import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {IMedia} from "../../types/IMedia";

interface IArticleTelegram {
    article_announce: string
    article_body: string
    article_date: string
    article_id: number
    article_keywords: any | null
    article_lead: string
    article_meta: string
    article_priority: any | null
    article_read: number
    article_title: string
    categories: any[]
    medias: IMedia[]
}

interface ITelegramSlice {
    isLoading: boolean
    data: IArticleTelegram[],
    chanel: string,
    membersCount: number
}

const initialState: ITelegramSlice = {
    isLoading: false,
    data: [],
    chanel: '',
    membersCount: 0
};

export const getTelegram = createAsyncThunk(
    'telegramSlice/getTelegram',
    async ({url}: {url: string}, { rejectWithValue }) => {
        try {
            const { data, status } = await axios.post(url);
            return {
                data: data?.data?.paginationSolrArticles?.articles || [],
                chanel: data?.data?.paginationSolrArticles?.telegram_data?.url || '',
                membersCount: data?.data?.paginationSolrArticles?.telegram_data?.membersCount || 0
            }
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const telegramSlice = createSlice({
    name: 'telegramSlice',
    initialState,
    reducers: {
        // toggleSideBar(state){
        //     localStorage.setItem('showSidebar', state.showSidebar ? '0' : '1');
        //     state.showSidebar = !state.showSidebar;
        // },
        // toggleMobileSideBar(state){
        //     state.showMobileSidebar = !state.showMobileSidebar;
        // },
        // closeMobileSideBar(state){
        //     state.showMobileSidebar = false;
        // }
    },
    extraReducers:{
        [getTelegram.pending.type]: (state: ITelegramSlice) => {
            state.isLoading = true
        },
        [getTelegram.fulfilled.type]: (state: ITelegramSlice, action: PayloadAction<{ data: IArticleTelegram[], chanel: string, membersCount: number}>) => {
            const { data, chanel, membersCount } = action.payload;
            state.isLoading = false;

            if(data.length){
                state.data = data
                state.chanel = chanel
                state.membersCount = membersCount
            }
        },
    }
});

export const { } = telegramSlice.actions;
export default telegramSlice.reducer;