import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import 'normalize.css/normalize.css';
import './assets/styles/split.scss';
import './assets/styles/base.module.scss';
import {setupStore} from "./store/store";
import {API_KEY_RECAPTCHA} from "./constants";

const store = setupStore();

ReactDOM.render(
    <Provider store={store}>
        <GoogleReCaptchaProvider reCaptchaKey={API_KEY_RECAPTCHA}>
            <App />
        </GoogleReCaptchaProvider>
    </Provider>,
    document.getElementById('root')
);

