import {ITimeMark} from "./component/DatePicker/Range";
import {DATE_PICKER_LABEL, DatePickerLabel} from "./component/DatePicker/DatePickerHeader";
import {LANG, THEME} from "./types/IUser";

export const APP = process.env.REACT_APP_BASE_URL;
export const CDN_IMAGE_DOMAIN = process.env.REACT_APP_CDN_IMAGE;
export const API_KEY_RESCALER = process.env.REACT_APP_RESCALER_API_KEY;
export const API_KEY_RECAPTCHA = process.env.REACT_APP_RECAPTCHA_KEY;
export const URL_WEBSOCKET = process.env.REACT_APP_WEBSOCKET + 'polling?ticket=';
export const URL_PULLING = APP + 'polling';

export const TELEGRAM_BOT = 'https://t.me/TRMNL_bot';
export const MAIL = 'podpiska@ria.ru';
export const VIDEO_RIA_URL = 'https://nfw.ria.ru/flv/file.aspx?type=flv&id=';
export const PREMIUM_USER_TYPE = 14; //2
export const SID_MEDIA = 'sid_1092';
export const SID_EXCLUSIVE = 'sid_1008';
export const TMP_QUERY = '__TMP__';

export const LIST_FIELDS_DIFF = ['fields', 'case_sensitive', 'media_type', 'stopwords', 'query', 'match', 'ids', 'id'];
export const FEED_END_DATE = '9999-12-31';

export const QUERY_LENGTH = 50;//50

export const SUBSCRIBE = {
    [LANG.RU]: {
        link: 'https://россиясегодня.рф/web_terminal/'
    },
    [LANG.EN]: {
        link: 'https://rossiyasegodnya.com/web_terminal/'
    },
    [LANG.ES]: {
        link: 'https://rossiyasegodnya.com/web_terminal/'
    },
    [LANG.CN]:{
        link: 'https://jinrieluosi.cn/foreign-news-feeds/'
    },
    [LANG.FA]: {
        link: 'https://rossiyasegodnya.com/web_terminal/'
    },
    [LANG.EG]: {
        link: 'https://rossiyasegodnya.com/web_terminal/'
    }
};

export const TELEGRAM = {
    [LANG.RU]: {
        widget: 'https://ria.ru/files/tgparser/ria.html',
        link: 'https://t.me/rian_ru',
        link_2: 'https://t.me/sputniknewsint',
        channel: APP + 'api/user/telegram_widget/rus/',
    },
    [LANG.EN]: {
        widget: 'https://ria.ru/files/tgparser/ria_eng.html',
        link: 'https://t.me/rianovostieng',
        link_2: 'https://t.me/rianovostieng',
        channel: APP + 'api/user/telegram_widget/eng/',
    },
    [LANG.ES]: {
        widget: '',
        link: '',
        link_2: '',
    },
    [LANG.CN]: {
        widget: '',
        link: '',
        link_2: '',
    },
    [LANG.FA]: {
        widget: 'https://ria.ru/files/tgparser/ria_farsi.html',
        link: 'https://t.me/RiaFarsi',
        link_2: 'https://t.me/RiaFarsi',
        channel: APP + 'api/user/telegram_widget/fas/',
    },
    [LANG.EG]: {
        widget: 'https://ria.ru/files/tgparser/ria_arabic.html',
        link: 'https://t.me/RiaArabic',
        link_2: 'https://t.me/RiaArabic',
        channel: APP + 'api/user/telegram_widget/ara/',
    },
};

export const SIDS = {
    [LANG.RU]: {
        INDEX: '',
        INDEX_ARTICLE: 'sid_1108',
        MEDIA: 'sid_1090',
        ARTICLE_NEWS: 'sid_587',
        TOP_NEWS: 'sid_1091',
        OPINION: 'sid_1092',
        LENTA: 'sid_951',
        EXPORT: 'sid_1078',
        SPIEF: 'sid_979',
        ALL_NEWS: 'sid_587',
        ELECTION: 'sid_981'
    },
    [LANG.EN]: {
        INDEX: '',
        INDEX_ARTICLE: 'sid_1109',
        MEDIA: 'sid_1093',
        ARTICLE_NEWS: 'sid_160',
        TOP_NEWS: 'sid_1094',
        OPINION: 'sid_1095',
        LENTA: 'sid_329',
        EXPORT: '',
        SPIEF: '',
        ALL_NEWS: 'sid_160',
        ELECTION: ''
    },
    [LANG.ES]: {
        INDEX: 'sid_1074',
        INDEX_ARTICLE: 'sid_1110',
        MEDIA: 'sid_1102',
        ARTICLE_NEWS: 'sid_938',
        TOP_NEWS: 'sid_1103',
        OPINION: 'sid_1104',
        LENTA: 'sid_960',
        EXPORT: '',
        SPIEF: '',
        ALL_NEWS: 'sid_938',
        ELECTION: ''
    },
    [LANG.CN]: {
        INDEX: 'sid_991',
        INDEX_ARTICLE: 'sid_1111',
        MEDIA: 'sid_1105',
        ARTICLE_NEWS: 'sid_608',
        TOP_NEWS: 'sid_1106',
        OPINION: 'sid_1107',
        LENTA: 'sid_959',
        EXPORT: '',
        SPIEF: '',
        ALL_NEWS: 'sid_608',
        ELECTION: ''
    },
    [LANG.FA]: {
        INDEX: '',
        INDEX_ARTICLE: 'sid_1113',
        MEDIA: 'sid_1099',
        ARTICLE_NEWS: 'sid_1025',
        TOP_NEWS: 'sid_1100',
        OPINION: 'sid_1101',
        LENTA: 'sid_1026',
        EXPORT: '',
        SPIEF: '',
        ALL_NEWS: 'sid_1025',
        ELECTION: ''
    },
    [LANG.EG]: {
        INDEX: '',
        INDEX_ARTICLE: 'sid_1112',
        MEDIA: 'sid_1096',
        ARTICLE_NEWS: 'sid_942',
        TOP_NEWS: 'sid_1097',
        OPINION: 'sid_1098',
        LENTA: 'sid_330',
        EXPORT: '',
        SPIEF: '',
        ALL_NEWS: 'sid_942',
        ELECTION: ''
    }
};

export const DATE_FORMAT = {
    [LANG.RU]: {
        FORMAT_TIME: 'HH:mm',
        FORMAT_DATE: 'DD.MM.YYYY',
        FORMAT_SHORT_DATE: 'DD.MM.YY',
        FORMAT_TIME_AND_DATE: 'DD.MM.YYYY HH:mm',
        FORMAT_DATEPICKER: 'DD.MM.YYYY',
    },
    [LANG.EN]: {
        FORMAT_TIME: 'HH:mm',
        FORMAT_DATE: 'DD.MM.YYYY',
        FORMAT_SHORT_DATE: 'DD.MM.YY',
        FORMAT_TIME_AND_DATE: 'DD.MM.YYYY HH:mm',
        FORMAT_DATEPICKER: 'DD.MM.YYYY',
    },
    [LANG.ES]: {
        FORMAT_TIME: 'HH:mm',
        FORMAT_DATE: 'DD.MM.YYYY',
        FORMAT_SHORT_DATE: 'DD.MM.YY',
        FORMAT_TIME_AND_DATE: 'DD.MM.YYYY HH:mm',
        FORMAT_DATEPICKER: 'DD.MM.YYYY',
    },
    [LANG.CN]: {
        FORMAT_TIME: 'HH:mm',
        FORMAT_DATE: 'DD.MM.YYYY',
        FORMAT_SHORT_DATE: 'DD.MM.YY',
        FORMAT_TIME_AND_DATE: 'DD.MM.YYYY HH:mm',
        FORMAT_DATEPICKER: 'DD.MM.YYYY',
    },
    [LANG.EG]: {
        FORMAT_TIME: 'HH:mm',
        FORMAT_DATE: 'DD.MM.YYYY',
        FORMAT_SHORT_DATE: 'DD.MM.YY',
        FORMAT_TIME_AND_DATE: 'DD.MM.YYYY HH:mm',
        FORMAT_DATEPICKER: 'DD.MM.YYYY',
    },
    [LANG.FA]: {
        FORMAT_TIME: 'HH:mm',
        FORMAT_DATE: 'jDD.jMM.jYYYY',
        FORMAT_SHORT_DATE: 'jDD.jMM.jYY',
        FORMAT_TIME_AND_DATE: 'jDD.jMM.jYYYY HH:mm',
        FORMAT_DATEPICKER: 'DD.MM.YYYY',
        // FORMAT_TIME: 'HH:mm',
        // FORMAT_DATE: 'DD.MM.YYYY',
        // FORMAT_SHORT_DATE: 'DD.MM.YY',
        // FORMAT_TIME_AND_DATE: 'DD.MM.YYYY HH:mm'
    }
};

export const SIDS_RTL = ['sid_942', 'sid_1023', 'sid_330', 'sid_1022', 'sid_1024', 'sid_1096', 'sid_1025', 'sid_1026', 'sid_1099', 'sid_1100', 'sid_1101'];

export const THEME_STYLED = {
    [THEME.RIA]: {
        '--color-red': '#E74A3A',
        '--color-grey': '#545454',
        '--color-light': '#E1EFFF',
        '--color-light2': '#CCDFF3',
        '--color-link': '#1345AE',
        '--color-link-hover': '#3B4F8D',
        '--color-keywords': '#3252B6',
        '--color-header': '#344370',
        '--color-blue': '#44579E',
        '--color-blue2': '#F3FAFE',
        '--color-dark': '#03143E',
        '--color-darkBlue': '#03143E',
        '--color-main': '#354577',
        '--color-subscribe': '#010f4d',
        '--color-background': '#FDFEFF',
        '--color-warning': '#FFFB9C',
        '--color-input': '#DADDEC',
        '--color-border': '#5F72AF',
        '--color-day-1000': '#A5D6F1',
        '--color-day-500': '#BEE7FF',
        '--color-day-350': '#BEE7FF',
        '--color-day-250': '#FFFFFF',
        '--color-day-0': '#EDF3F6',
    },
    [THEME.SPUNTIK]: {
        '--color-red': '#E74A3A',
        '--color-grey': '#545454',
        '--color-light': '#FBE1BC',
        '--color-light2': '#FBE1BC',
        '--color-link': '#FF9600',
        '--color-link-hover': '#FFAC39',
        '--color-keywords': '#FF9600',
        '--color-header': '#333231',
        '--color-blue': '#E79A2D',
        '--color-blue2': '#FBE1BC',
        '--color-dark': '#FFAC39',
        '--color-darkBlue': '#FFAC39',
        '--color-main': '#FFAC39',
        '--color-subscribe': '#FFAC39',
        '--color-background': '#fffbf4',
        '--color-warning': '#FFFB9C',
        '--color-input': '#FFCE79',
        '--color-border': '#878787',
        '--color-day-1000': '#FFE24A',
        '--color-day-500': '#FFED8C',
        '--color-day-350': '#FFF4BA',
        '--color-day-250': '#FFFFFF',
        '--color-day-0': '#F5F1DB',
    }
};

export const OLD_TERMINAL = {
    [LANG.RU]: 'https://old.tr.ria.ru/login.html',
    [LANG.EN]: 'https://old.wire.sputniknews.com/',
    [LANG.CN]: 'https://old.wire.sputniknews.com/login.html?lang=cn#?lang=cn',
    [LANG.ES]: 'https://old.wire.sputniknews.com/login.html?lang=es#?lang=es',
    [LANG.EG]: 'https://old.wire.sputniknews.com/login.html?lang=ar#?lang=ar',
    [LANG.FA]: 'https://old.wire.sputniknews.com/login.html?lang=da#?lang=da'
};

export const URL = {
    GET_FLOOR: APP + 'api/index2/',
    GET_INFO_USER: APP + 'api/user/my_info/',
    GET_TICKET: APP + 'api/user/ticket/',
    LOGIN: APP + 'api/user/login/',
    REGISTRATION:  APP + 'api/user/register/',
    CONFIRM_EMAIL:  APP + 'api/user/confirm_email/',
    PASSWORD_RECOVERY:  APP + 'api/user/password_recovery_set_password/',
    LOGIN_RECOVERY:  APP + 'api/user/password_recovery_link/',
    CHECK_CREDENTIALS: APP + 'api/user/check_credentials/',
    LOGIN_MULTISESSION: APP + 'api/user/eventhandler/',
    LOGIN_SOCIAL_VK: APP + 'api/id/vkontakte/request/',
    LOGIN_SOCIAL_OK: APP + 'api/id/ok/request/',
    LOGIN_SOCIAL_APPLE: APP + 'api/id/apple/request/',
    LOGIN_SOCIAL_GOOGLE: APP + 'api/id/google/request/',
    LOGOUT: APP + 'api/user/logout/',
    SEARCH: APP + 'api/user/search/',
    INDEX_FEED: APP + 'api/index_feed/',
    ALL_MY_FEEDS: APP + 'api/user/all_my_feeds/',
    SET_PREFESENCES: APP + 'api/user/set_preferences/',
    SORT_PREFERENS: APP + 'api/user/sort_preferences/',
    DELETE_PREFERENS: APP + 'api/user/delete_preferences/',
    GET_ARTICLE: APP + 'api/user/articleById/',
    EXPORT: APP + 'api/user/export/',
    STATISTICS: APP + 'api/user/statistics/',
    SID_STATISTICS: APP + 'api/user/sid_statistics/',
    GET_HISTORY: APP + 'api/user/get_export_history/',
    LOG_HISTORY: APP + 'api/user/log_export/',
    // PIVOT_STATISTICS: APP + 'api/user/pivot_statistics/',
    PIVOT_STATISTICS: APP + 'api/user/pivot_keywords_statistics/',
    GET_CODE_TELEGRAM: APP + 'api/user/get_onetime_code/',
    SET_DEBUG: APP + 'api/user/debug_log/'
};

export const ROUTE = {
    INDEX: '/',
    FEED: '/feed/',
    QUERIES: '/queries/',
    FAVORITE: '/favorite/',
    EXPORT: '/settings/adjustment/',
    ADJUSTMENT: '/settings/adjustment/',
    LENTA: '/settings/lenta/',
    HISTORY: '/settings/history/',
    NEWSLETTER: '/settings/newsletter/',
    ARTICLE: '/news/',
    PRINT: '/print/',
    ANALYTICS: '/analytics/',
    FAQ: '/user_manual/'
};

export const SHARE_URL = {
    TELEGRAM: 'https://t.me/share/url',
    VK: 'https://vk.com/share.php',
    OK: 'https://connect.ok.ru/offer'
};

export const TYPE_PREFERENCES = {
    GROUP: 'group',
    QUERY: 'query',
    ARTICLE: 'article',
    MAILING_LIST: 'mailing_lists',
    PROFILE_LENTA: 'profile_lenta',
    PROFILE_EXPORT: 'profile_export',
    PINNED_ARTICLE: 'pinned_article'
};

export const LENTA_GROUP = [
    [
        {
            id: 970590760
        }, {
            id: 970590761
        },{
            id: 970590765
        },{
            id: 970590764
        }
    ],[
        {
            id: 970590767
        },{
            id: 970590793
        },{
            id: 1066992951
        },{
            id: 970590773
        },{
            id: 970590787
        },{
            id: 970590788
        },{
            id: 970590762
        },{
            id: 970590763
        }
    ],[
        {
            id: 970590789
        },{
            id: 1036099390
        },{
            id: 1084177251
        },{
            id: 1084177252
        },{
            id: 1135378919
        }
    ],[
        {
            id: 100
        },{
            id: 980686141
        },{
            id: 970590790
        },{
            id: 970590791
        },{
            id: 970590792
        }
    ]
];

export const PUBLIC_SIDS = [{
        lang: LANG.RU,
        ids: [SIDS[LANG.RU].MEDIA, SIDS[LANG.RU].TOP_NEWS, SIDS[LANG.RU].OPINION, SIDS[LANG.RU].EXPORT, SIDS[LANG.RU].SPIEF, SIDS[LANG.RU].ELECTION],
    }, {
        lang: LANG.EN,
        ids: [SIDS[LANG.EN].MEDIA, SIDS[LANG.EN].TOP_NEWS, SIDS[LANG.EN].OPINION],
    }, {
        lang: LANG.ES,
        ids: [SIDS[LANG.ES].MEDIA, SIDS[LANG.ES].TOP_NEWS, SIDS[LANG.ES].OPINION],
    }, {
        lang: LANG.CN,
        ids: [SIDS[LANG.CN].MEDIA, SIDS[LANG.CN].TOP_NEWS, SIDS[LANG.CN].OPINION],
    }, {
        lang: LANG.EG,
        ids: [SIDS[LANG.EG].MEDIA, SIDS[LANG.EG].TOP_NEWS, SIDS[LANG.EG].OPINION],
    }, {
        lang: LANG.FA,
        ids: [SIDS[LANG.FA].MEDIA, SIDS[LANG.FA].TOP_NEWS, SIDS[LANG.FA].OPINION],
}];

export const PRIVATE_SIDS = [{
    lang: LANG.RU,
    ids: ['sid_900', 'sid_66', 'sid_902', 'sid_905'],
}, {
    lang: LANG.EN,
    ids: ['sid_160'],
}, {
    lang: LANG.ES,
    ids: ['sid_938'],
}, {
    lang: LANG.CN,
    ids: ['sid_608'],
}, {
    lang: LANG.EG,
    ids: ['sid_942'],
}, {
    lang: LANG.FA,
    ids: ['sid_1026'],
}];


export const PERIOD_DATEPICKER: DatePickerLabel[] = [
    {
        id: 1,
        hours: 3,
        days: 0,
        months: 0,
        years: 0,
        picker: DATE_PICKER_LABEL.TIME,
    },{
        id: 2,
        hours: 6,
        days: 0,
        months: 0,
        years: 0,
        picker: DATE_PICKER_LABEL.TIME,
    },{
        id: 3,
        hours: 12,
        days: 0,
        months: 0,
        years: 0,
        picker: DATE_PICKER_LABEL.TIME,
    },{
        id: 4,
        hours: 24,
        days: 0,
        months: 0,
        years: 0,
        picker: DATE_PICKER_LABEL.TIME,
    },{
        id: 5,
        hours: 0,
        days: 3,
        months: 0,
        years: 0,
        picker: DATE_PICKER_LABEL.DAYS,
    },{
        id: 6,
        hours: 0,
        days: 7,
        months: 0,
        years: 0,
        picker: DATE_PICKER_LABEL.DAYS,
    },{
        id: 7,
        hours: 0,
        days: 14,
        months: 0,
        years: 0,
        picker: DATE_PICKER_LABEL.DAYS,
    },{
        id: 8,
        hours: 0,
        days: 0,
        months: 1,
        years: 0,
        picker: DATE_PICKER_LABEL.DAYS,
    },{
        id: 9,
        hours: 0,
        days: 0,
        months: 3,
        years: 0,
        picker: DATE_PICKER_LABEL.DAYS,
    },{
        id: 10,
        hours: 0,
        days: 0,
        months: 3,
        years: 0,
        picker: DATE_PICKER_LABEL.DAYS,
    },{
        id: 11,
        hours: 0,
        days: 0,
        months: 12,
        years: 0,
        picker: DATE_PICKER_LABEL.DAYS,
    },
];

export const TIME_MARK_MOBILE: ITimeMark[] = [{
    index: 0,
    mark: '00:00',
    hour: 0,
    minute: 0,
},{
    index: 2,
    mark: '06:00',
    hour: 6,
    minute: 0,
},{
    index: 4,
    mark: '12:00',
    hour: 12,
    minute: 0,
},{
    index: 6,
    mark: '18:00',
    hour: 18,
    minute: 0,
},{
    index: 8,
    mark: '23:59',
    hour: 23,
    minute: 59,
}];

export const TIME_MARK: ITimeMark[] = [{
    index: 0,
    mark: '00:00',
    hour: 0,
    minute: 0,
}, {
    index: 1,
    mark: '03:00',
    hour: 3,
    minute: 0,
},{
    index: 2,
    mark: '06:00',
    hour: 6,
    minute: 0,
},{
    index: 3,
    mark: '09:00',
    hour: 9,
    minute: 0,
},{
    index: 4,
    mark: '12:00',
    hour: 12,
    minute: 0,
},{
    index: 5,
    mark: '15:00',
    hour: 15,
    minute: 0,
},{
    index: 6,
    mark: '18:00',
    hour: 18,
    minute: 0,
},{
    index: 7,
    mark: '21:00',
    hour: 21,
    minute: 0,
},{
    index: 8,
    mark: '23:59',
    hour: 23,
    minute: 59,
}];

export const OPTIONS_SIZE_TITLE = ['22px', '24px', '26px', '28px', '30px','32px', '34px', '36px', '38px'];

export const OPTIONS_SIZE_TEXT = ['10px', '12px', '14px', '16px', '18px','20px', '22px', '24px'];

export const OPTIONS_SIZE_LINE_HEIGHT = ['10px', '12px', '14px', '16px', '18px','20px', '22px', '24px', '26px', '28px', '30px','32px', '34px', '36px', '38px'];

export const COLORS_TEXT = ['#99BAFF', '#FCA9A9', '#FFFB9C','#B4EA93','#F598D0', '#B1AFFF'];

export const COLORS_FAVORITE = ['#F3FFFE', '#FFF4F5', '#FFFEEC','#F4FFF7','#FEF2FB', '#F8F6FF'];

export const COLORS_GRAPH = ['#FF6B6B', '#3AB795', '#FFD93D', '#424874', '#4D96FF', '#81559B', '#6BCB77', '#7D80DA', '#EFA48B', '#F5E663', '#C772E5', '#E05263', '#7CB67D', '#EF2D56', '#8338EC', '#A7754D'];

export const COLORS_COUNT_DATEPICKER = [
    {
        id: 1,
        title: '< 300',
        color: '#EDF3F6',
    },
    {
        id: 2,
        title: '~ 300',
        color: 'transparent',
    },
    {
        id: 3,
        title: '> 300',
        color: '#DCF3FF',
    },
    {
        id: 4,
        title: '> 500',
        color: '#BEE7FF',
    },
    {
        id: 5,
        title: '> 1000',
        color: '#A5D6F1',
    }
];

export const COLORS_COUNT_DATEPICKER_SPUTNIK = [
    {
        id: 1,
        title: '< 300',
        color: '#F5F1DB',
    },
    {
        id: 2,
        title: '~ 300',
        color: 'transparent',
    },
    {
        id: 3,
        title: '> 300',
        color: '#FFF4BA',
    },
    {
        id: 4,
        title: '> 500',
        color: '#FFED8C',
    },
    {
        id: 5,
        title: '> 1000',
        color: '#FFE24A',
    }
];