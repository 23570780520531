import React, { FC, useState, useEffect, MouseEvent } from 'react';
import cn from 'classnames';
import Icon from '../../Icon';
import { TooltipProfile } from '../../Tooltip';
import Spinner from '../../Spinner';
import ListFavorite from './ListFavorite';
import ListItemPreview from '../ListItemPreview';
import ListShare from './ListShare';
import ListCopy from './ListCopy';
import ListMenu from './ListMenu';
import Checkbox from "../../Input/Checkbox/Checkbox";
import {IArticle} from "../../../types/IArticle";
import {ICON_VARIANT} from "../../../assets/svg";
import {arrayCompareSome, containsSids, dateListFormat, getKeywords, objKeys} from "../../../utils/utils";
import {INPUT_VARINAT, OnCheckboxChange} from "../../../types/IInput";
import {useTranslate} from "../../../hooks/useTranslate";
import {useAppSelector} from "../../../hooks/redux";
import {useGetColor} from "../../../hooks/useGetColor";
import {useGetArticle} from "../../../hooks/useGetArticle";
import css from './ListItem.module.scss'
import {useUserPremium} from "../../../hooks/useUserPremium";
import {SID_EXCLUSIVE} from "../../../constants";

export enum LIST_VARIANT {
    STANDART = 'standart',
    COMPACT = 'compact',
    TITLE = 'head',
    LEAD = 'lead',
    MINI = 'mini',
    WIDGET = 'widget'
}


export interface PropsListItem {
    articleId: number,
    isPreview?: boolean,
    checked: boolean,
    pined: boolean,
    backgoundFavorite?: string | null,
    onChecked?: (arg: OnCheckboxChange) => void,
    onPin: (arg: IArticle) => void,
    onClick: (arg: IArticle) => void,
    variant: LIST_VARIANT,
    preview: boolean,
    priority: boolean,
    isChecked: boolean
}


const ListItem: FC<PropsListItem> = ({articleId, pined, checked,  isChecked, priority, backgoundFavorite = null, variant, preview, onPin, onChecked, onClick}) => {
    const article = useGetArticle(articleId);
    const { run } = useAppSelector(state => state.tour);
    const { user } = useAppSelector(state => state.auth);
    const [show, setShow] = useState<boolean>(false);
    const [showLead, setShowLead] = useState<boolean>(false);
    const translate = useTranslate();
    const color = useGetColor();
    const terms = objKeys(user?.terms || {});
    const premium = useUserPremium();
    const { isLoading, articleId: mainId } = useAppSelector(state => state.article);
    const keywords = getKeywords(article?.article_keywords);
    const sids = article?.categories.map(item => item.list_sid) || [];
    const isActive = premium || (sids.length && arrayCompareSome(terms, sids)); //статья активна для пользователя

    useEffect(() => {
        if(mainId === articleId){
            setShow(preview)
        }
    },[preview]);

    if(!article){
        return null
    }

    const handleClick = () => {
        if(!show){
            onClick(article);
        }

        if(preview && isActive){
            setShow(!show)
        }
    };

    const handlePin = () => {
        onPin(article)
    };

    const onShowLead = (e: MouseEvent) => {
        e.stopPropagation();
        setShowLead(!showLead);
    };

    const getBackground = () => {
        if(article.article_id === mainId){
            return ''
        }else if(pined){
            return '#F5F5F5'
        } else if(checked){
            return '#F3FAFE'
        } else if(backgoundFavorite){
            return backgoundFavorite
        }
        return '#fff'
    };

    const getIsRead = () => {
        if(pined){
            return false
        }
        return article_read > 0
    };

    if(show && (mainId !== articleId || !isLoading)){
        return <ListItemPreview article={article} onClick={handleClick}/>
    }

    const {article_date, article_title, article_lead, article_id, article_read, article_priority, categories } = article;

    return (
        <div
            // style={{backgroundColor: getBackground()}}
            className={cn(css.item, css[variant], css['priority-' + (priority ? article_priority : '0')], {[css.isRead]: getIsRead(), [css.main]: article_id === mainId, [css.disabled]: !isActive})}>
            <div className={css.date}>{dateListFormat(article_date)}</div>
            <div className={css.content}>
                <div className={css.text} onClick={handleClick}>
                    <div className={css.title}>
                        <span dangerouslySetInnerHTML={{ __html: article_title}}/>
                        {containsSids([article], [SID_EXCLUSIVE]).length ? <div className={cn(css.external, css.main)}>{translate.list.exclusive}</div> : null}
                        {categories.filter(list => list.list_is_internal === 0).map(item =>
                            <TooltipProfile
                                key={item.list_sid}
                                title={translate.tooltip.list.external}
                            >
                                <div className={css.external}>{item.list_title}</div>
                            </TooltipProfile>
                        )}
                    </div>
                    {pined && [LIST_VARIANT.LEAD, LIST_VARIANT.TITLE, LIST_VARIANT.STANDART, LIST_VARIANT.COMPACT].includes(variant)
                        ? <div className={css.announce}>
                            <div className={css.leadToggle} onClick={onShowLead}>
                                {translate.list.showLead}
                                <div className={css.leadIcon}>
                                    <Icon name={showLead ? ICON_VARIANT.ARROW_UP : ICON_VARIANT.ARROW_DOWN}/>
                                </div>
                            </div>
                            {showLead ? <div className={css.leadText}  dangerouslySetInnerHTML={{__html: article_lead}}/> : null}
                        </div>
                        : null
                    }
                    {!pined && [LIST_VARIANT.LEAD].includes(variant) && article_lead !== ''
                        ? <div className={css.announce}>
                            <div className={css.leadText} dangerouslySetInnerHTML={{ __html: article_lead}}/>
                        </div>
                        : null
                    }
                </div>
                {[LIST_VARIANT.LEAD, LIST_VARIANT.STANDART].includes(variant)
                    ? <div className={css.annotate}>
                        {keywords.length
                            ? <div className={css.keyword}>
                                {keywords.map((item, i) => <div className={css.keywordItem} key={i} dangerouslySetInnerHTML={{ __html: item}}/>)}
                            </div>
                            : null
                        }

                        {article?.medias?.length
                            ? <div className={css.media}>
                                {article?.medias.find(media => media.media_variants.find(mv => mv.media_variant_type === 'photo'))
                                    ? translate.list.photo
                                    : null
                                }
                                {article?.medias.find(media => media.media_variants.find(mv => mv.media_variant_type === 'photo')) && article?.medias.find(media => media.media_variants.find(mv => mv.media_variant_type === 'video'))
                                    ? ' / '
                                    : null
                                }
                                {article?.medias.find(media => media.media_variants.find(mv => mv.media_variant_type === 'video'))
                                    ? translate.list.video
                                    : null
                                }
                            </div>
                            : null
                        }
                    </div>
                    : null
                }
            </div>
            {[LIST_VARIANT.COMPACT, LIST_VARIANT.LEAD, LIST_VARIANT.TITLE, LIST_VARIANT.STANDART].includes(variant)
                ? <>
                    <div className={cn(css.pin, {[css.pined]: pined})}>
                        <TooltipProfile
                            title={translate.tooltip.list.pin}
                        >
                            <Icon
                                name={pined ? ICON_VARIANT.PIN_FILL : ICON_VARIANT.PIN}
                                onClick={handlePin}/>
                        </TooltipProfile>
                    </div>
                    <div className={css.favorite}>
                        <TooltipProfile
                            title={translate.tooltip.list.favorite}
                        >
                            <ListFavorite articleId={articleId}/>
                        </TooltipProfile>
                    </div>
                    <div className={cn(css.slider, {[css.checked]: checked || run})}>
                        <div className={css.controls}>
                            <ListMenu article={article}/>
                            <ListCopy article={article}/>
                            <ListShare article={article}/>
                        </div>
                        {onChecked && isChecked
                            ? <div className={cn(css.checkbox, {[css.checked]: checked || run})} data-tour={"list-checkbox"}>
                                <Checkbox variant={INPUT_VARINAT.BLUE} name={article_id.toString()} value={checked} onChange={onChecked}/>
                            </div>
                            : null
                        }
                    </div>
                </>
                : null
            }

            {(show && isLoading && articleId === article_id)
                ? <div className={css.loader}>
                    <Spinner height={'100%'}/>
                </div>
                : null
            }
        </div>
    );
};

export default ListItem